<template>
    <div>
        <custom-floating-label
            :config="{
                label: 'SiteID',
                name: 'wrapper',
                line: false,
                scale: false,
            }"
        >
            <input
                disabled
                type="text"
                class="attributeEntry mb-4"
                v-model="projectID"
            />
        </custom-floating-label>
        <custom-floating-label
            :config="{
                label: 'Type',
                name: 'wrapper',
                line: false,
                scale: false,
            }"
        >
            <input
                disabled
                type="text"
                class="attributeEntry mb-4"
                v-model="drawingAttributes.geomLocType"
            />
        </custom-floating-label>
        <custom-floating-label
            :config="{
                label: 'Label',
                name: 'wrapper',
                line: false,
                scale: false,
            }"
        >
            <input
                type="text"
                class="attributeEntry mb-4"
                v-model="drawingAttributes.geomLocName"
            />
        </custom-floating-label>
        <custom-floating-label
            :config="{
                label: 'Description',
                name: 'wrapper',
                line: false,
                scale: false,
            }"
        >
            <input
                type="text"
                class="attributeEntry mb-4"
                v-model="drawingAttributes.geomLocDesc"
            />
        </custom-floating-label>
        <custom-floating-label
            :config="{
                label: 'Comment',
                name: 'wrapper',
                line: false,
                scale: false,
            }"
        >
            <input
                type="text"
                class="attributeEntry mb-4"
                v-model="drawingAttributes.geomComment"
            />
        </custom-floating-label>
        <div class="modalButtonRow">
            <button
                class="modalButton deleteButton"
                v-show="showEditButtons"
                @click="deleteFromDatabase"
            >
                Delete geometry
            </button>
            <!-- I removed this because geometry drawing is Phase 2 -->
            <!-- <button
                class="modalButton"
                v-show="showEditButtons"
                @click="editGeometry"
            >
                Edit geometry
            </button> -->
            <button class="modalButton" @click="sendToDatabase">
                Save to database
            </button>
        </div>
    </div>
</template>

<script>
import { spatialFunctions } from '../../utilities/spatialFunctions';
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import CustomFloatingLabel from '../CustomFloatingLabel.vue';
import { spatialService } from '../../services/spatial.services';

import Vue from 'vue';
Vue.directive('hide', function(el, binding) {
    el.style.visibility = !binding.value ? 'visible' : 'hidden';
});

export default {
    name: 'attributeModal',
    components: {
        CustomFloatingLabel,
    },
    watch: {
        showAttributeModal: function(val) {
            if (val === true) {
                this.$refs.attributeModal.show();
            } else {
                this.$refs.attributeModal.hide();
            }
        },
    },
    props: {
        projectID: {
            type: Number,
            default: 0,
            required: true,
        },
    },
    mounted() {},

    data() {
        return {};
    },
    methods: {
        ...mapActions('common', ['setForceRefreshMap']),
        ...mapMutations('emergencyManagement', {
            setShowAttributeModal: 'updateShowAttributeModal',
        }),
        loadEditFeature() {},
        editGeometry() {
            let editFeature = {
                geometry: this.drawingAttributes.geom,
            };

            this.$emit(
                'editGeometry',
                spatialFunctions.webMerc2DD(editFeature)
            );
        },
        getKey(obj, val) {
            return Object.keys(obj).find((key) => obj[key].includes(val));
        },
        async sendToDatabase() {
            var geomJson = {
                GeoID: this.drawingAttributes.geoID,
                ProjectID: this.drawingAttributes.projectID,
                Geometry: this.drawingAttributes.geomWKT,
                GeoType: this.drawingAttributes.geomLocType,
                GeoName: this.drawingAttributes.geomLocName,
                GeoDescription: this.drawingAttributes.geomLocDesc,
                Comment: this.drawingAttributes.geomComment,
            };

            await spatialService.saveToDatabase(geomJson).then((res) => {
                if (res.status == 200) {
                    this.setShowAttributeModal(false);
                    this.$emit('updateGeometryLayer', {
                        geomType: this.drawingAttributes.geomWKT.substring(
                            0,
                            this.drawingAttributes.geomWKT.indexOf(' ')
                        ),
                    });
                    alert('Feature successfully sent to database');
                } else {
                    alert('Error inserting feature to database');
                }
            });
            this.forceMapUpdate();
        },

        async deleteFromDatabase() {
            await spatialService
                .deleteFromDatabase(this.drawingAttributes.geoID)
                .then((res) => {
                    if (res.status == 200) {
                        this.setShowAttributeModal(false);
                        this.$emit('deleteGeometry', {
                            geomType: this.drawingAttributes.geomWKT.substring(
                                0,
                                this.drawingAttributes.geomWKT.indexOf(' ')
                            ),
                        });
                        alert('Feature successfully deleted from database');
                    } else {
                        alert('Error deleting feature from database');
                    }
                });
            this.forceMapUpdate();
        },
        forceMapUpdate() {
            this.setForceRefreshMap(true);
        },
    },
    computed: {
        ...mapState('emergencyManagement', [
            'drawingAttributes',
            // 'showAttributeModal'
        ]),
        ...mapGetters('emergencyManagement', {
            showAttributeModal: 'getShowAttributeModal',
        }),
        showEditButtons() {
            return this.drawingAttributes.geoID != 0;
        },
    },
};
</script>

<style>
.modalBody {
    justify-content: center;
}
.attributeEntry {
    height: 48px !important;
    border-radius: 0;
    background-color: #fff;
    border-left: 0;
    border-right: 0;
    padding-left: 45px;
    border-top: 1px solid #dddddd;
    background: no-repeat;
    width: 90%;
}

.modalButtonRow {
    margin-top: 10px;
    display: flex;
    height: 10%;
    justify-content: space-around;
}
.modalButton {
    color: white;
    width: 25%;
    background-color: gray;
    border-radius: 4px;
    border-width: 2px;
    border-style: solid;
    border-color: gray;
}
.deleteButton {
    color: rgb(255, 20, 20) !important;
    border-color: rgb(255, 20, 20) !important;
    background-color: white !important;
}

@media only screen and (min-width: 768px) {
    .input__container {
        width: 450px !important;
    }
}

@media only screen and (max-width: 769px) {
    .modalButton {
        width: 40%;
    }

    .input__container {
        border: 1px solid #c6c6c6;
        width: calc(90vw - 32px) !important;
    }
    .input__container .input__container.input__container--content {
        border: 1px solid #c6c6c6;
        width: 75vw !important;
    }

    .my-custom-floating-label {
        border: 1px solid #c6c6c6;
    }

    .EM {
        height: auto;
        max-height: 30vh;
        max-width: calc(90vw - 32px);
    }

    .EM .my-custom-floating-label .input__container {
        border: 1px solid #c6c6c6;
        width: 80%;
    }

    .attributeEntry {
        height: 48px !important;
        border-radius: 0;
        background-color: #fff;
        border-left: 0;
        border-right: 0;
        padding-left: 45px;
        border-top: 1px solid #dddddd;
        background: no-repeat;
        width: calc(90vw - 48px);
    }
}
</style>
